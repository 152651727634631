<template>
  <v-container>
    <add-domain-dialog v-model="showAddDomainModal"></add-domain-dialog>
    <v-row class="mb-4">
      <v-col >
        <v-btn @click='backLandingPage()' icon small>
          <v-icon color="primary" dense left>
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <span :style="{'color': `${isDark ? '#E7E3FCAD' : '#111B21'}`, 'font-size':'24px', 'font-weight': '700'}" class="ml-1 title">
          {{ $t('Manage Domain') }}
        </span>
      </v-col>
    </v-row>
    <v-row >
        <v-col cols="6" md="3" lg="2" class="mb-8" >
          <v-btn
            class="text-capitalize me-6 white--text"
            color="#1F979E"
            @click="showAddDomainModal = true"
          >
            <v-icon
              color="white"
              left
            >
              {{ icons.mdiPlus }}
            </v-icon>
            Add Domain
          </v-btn>
        </v-col>
        <v-col cols="6" md="4" lg="3" class="mb-8">
          <v-text-field
                v-model="search"
                :prepend-inner-icon="icons.mdiMagnify"
                label="Search domain"
                single-line
                hide-details
                dense
                outlined
                class="shrink"
              >
          </v-text-field>
        </v-col>
    </v-row>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="domainData"
      item-key="id"
      class="elevation-1"
    >
      <template #[`item.id`]="{ item }">
        <span :style="{'color': `${isDark ? '#E7E3FCAD' : '#111B21'}`}">{{ item.id }}</span>
      </template>
      <template #[`item.siteName`]="{ item }">
        <span :style="{'color': `${isDark ? '#E7E3FCAD' : '#111B21'} `}">{{ item.siteName }}</span>
      </template>
      <template #[`item.siteAddress`]="{ item }">
        <span :style="{'color': `${isDark ? '#E7E3FCAD' : '#111B21'} `}">{{ item.siteAddress }}</span>
      </template>
      <template #[`item.connectionType`]="{ item }">
        <span :style="{'color': `${isDark ? '#E7E3FCAD' : '#111B21'} `}">{{ item.connectionType }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col>
            <v-btn class="me-3" color="primary"  x-small outlined @click="deleteItem(item.id)"><v-icon >{{ icons.mdiTrashCan }}</v-icon></v-btn>
            <v-btn class="me-0" color="primary" x-small outlined><v-icon>{{ icons.mdiEyeOutline }}</v-icon></v-btn>
          </v-col>
        </v-row>
      </template>
      
    </v-data-table>
  </v-container>
</template>
<script>
import useAppConfig from '@/@core/@app-config/useAppConfig'
import AddDomainDialog from '@/views/build-landing-page/AddDomainDialog.vue'
import { mdiChevronLeft, mdiEyeOutline, mdiMagnify, mdiPlus, mdiTrashCan } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')
    return {
      icons: { mdiChevronLeft, mdiPlus, mdiMagnify, mdiTrashCan, mdiEyeOutline },
      isDark,
      search
    }
  },
  components: {AddDomainDialog},
  data() {
    return {
      singleSelect: false,
      showAddDomainModal: false,
      selected: [],
      headers: [
        { text: 'No', value: 'id', width:'5%'},
        { text: 'Site Name', value: 'siteName', width:'20%' },
        { text: 'Site Address (URL)', value: 'siteAddress', width:'30%'},
        { text: 'Connection Type', value: 'connectionType', width:'20%' },
        { text: 'Action', value: 'actions', sortable: false, align: "right", width:'15%' },
      ],
      domainData: [
        { id: '1', siteName: 'Landing Page 1', siteAddress: 'https://www.marketa.id', connectionType: 'Full Manage' },
        { id: '2', siteName: 'Landing Page 2', siteAddress: 'https://www.mysite.com', connectionType: 'Nameserver' },
        { id: '3', siteName: 'Landing Page 3', siteAddress: 'https://www.realtegic.id', connectionType: 'DNS' },
        { id: '4', siteName: 'Landing Page 4', siteAddress: 'https://www.marketa.id', connectionType: 'Full Manage' },
        { id: '5', siteName: 'Landing Page 5', siteAddress: 'https://www.mysite.com', connectionType: 'Nameserver' },
        { id: '6', siteName: 'Landing Page 6', siteAddress: 'https://www.realtegic.id', connectionType: 'DNS' },
        { id: '7', siteName: 'Landing Page 7', siteAddress: 'https://www.marketa.id', connectionType: 'Full Manage' },
        { id: '8', siteName: 'Landing Page 8', siteAddress: 'https://www.mysite.com', connectionType: 'Nameserver' },
        { id: '9', siteName: 'Landing Page 9', siteAddress: 'https://www.realtegic.id', connectionType: 'DNS' },
      ],
    }
  },
  methods: {
    deleteItem(id) {
      this.domainData = this.domainData.filter(data => data.id !== id)

      // Wait backend
    },
    backLandingPage() {
      this.$router.back()
    }
  },
  
}
</script>
<style>
</style>
